import React, { useState, useCallback, useEffect } from "react";

import { Co2Calculator } from "./Co2Calculator";

const getStoredPotential = () => {
  try {
    const stored = localStorage.getItem("potential");
    if (stored) {
      const potentialSizes = JSON.parse(stored);
      return potentialSizes;
    } else {
      return { 0.5: "", 2: "", 3: "", 6: "", 12: "", 25: "", 50: "" };
    }
  } catch (error) {
    console.error("Error parsing stored data", error);
    return { 0.5: "", 2: "", 3: "", 6: "", 12: "", 25: "", 50: "" };
  }
};

const MainSimple = ({ websiteLoc }) => {
  const [sizes, setSizes] = useState(() => ({
    // potential: { 0.5: "", 2: "", 3: "", 6: "", 12: "", 25: "", 50: "" },
    potential: getStoredPotential()
  }));

  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    if (!initialLoad) {
      localStorage.setItem("potential", JSON.stringify(sizes.potential));
    } else {
      setInitialLoad(false);
    }
  }, [sizes.potential]);

  const parseValue = (value) => (value === "" ? 0 : parseInt(value));

  const updateSizes = useCallback((value, size, isPotentialUsage) => {
    const nbr = parseValue(value);
    if (isNaN(nbr)) return;

    setSizes((prevSizes) => {
      const newPotential = isPotentialUsage
        ? { ...prevSizes.potential, [size]: value }
        : prevSizes.potential;

      return {
        potential: newPotential
      };
    });
  }, []);

  return (
    <>
      <div className="main">
        {/* <h3>{websiteLoc === "start" ? "Start Page" : "Calculator Page"}</h3> */}
        <Co2Calculator
          isPotentialUsage={true}
          sizes={sizes.potential}
          updateSizes={updateSizes}
        />
        <div style={{ fontSize: "10px" }}>
          The estimations in the “climate calculator” is based on:
          <ul>
            <li>
              Average weight of the most common uses waste containers per size.
            </li>
            <li>
              The estimated CO2 generated from 1kg fossil-based container is
              5,7kg.
            </li>
            <li>
              The reduction in CO2 is -66% with WoodSafe vs fossil-based
              polypropylene according to documented LCA.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MainSimple;
