import { Co2Calculator } from "./Co2Calculator";
import { Chart } from "./Chart";

const ActualUsageWS = ({ sizes, updateSizes }) => {
  // useEffect(() => {
  //   Object.entries(sizes.current).forEach(([key, value]) => {
  //     updateSizes(value, key, false);
  //   });
  // }, []);
  return (
    <>
      <Co2Calculator
        isPotentialUsage={false}
        potential={sizes.actualUsageWS}
        sizes={sizes.actualUsageWS}
        updateSizes={updateSizes}
        ratios={sizes.ratios}
        // forwardRef={potentialTableRef}
      />
      {/* <Chart
        ratios={sizes.ratios}
        totalRatio={sizes.totalRatio}
        // forwardRef={chartsRef}
      /> */}
    </>
  );
};

export default ActualUsageWS;
