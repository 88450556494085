import { useEffect, useState } from "react";
import {
  Co2Calculator,
  getTotalCo2Reduction,
  getTotalCo2,
  getFossilPlasticReduction,
  getTotalWeight
} from "./Co2Calculator";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ErrorBar,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
  Text,
  Line,
} from "recharts";

const getTotalSize = (sizes) => {
  if (!sizes) return 0;
  // console.log("sizes:", sizes);
  // console.log(`${key}x${value} = ${key * value}`);

  let sum = 0;
  for (const [key, value] of Object.entries(sizes)) {
    sum += key * value;
  }

  return sum;
};

export const OptimizingChart = ({ currentVol, optVol, currentCo2, woodsafeCo2, optCo2, volCheck = true }) => {
  const initialData = [
    {
      name: "Volume",
      current: 0,
      opt: 0
    },
    {
      name: "CO2",
      current: 0,
      opt: 0
    }
  ];
  const data = [
    {
      name: "Volume",
      currentVol: currentVol,
      optVol: optVol,
      currentCo2: 0,
      optCo2: 0
    },
    {
      name: "CO2",
      currentVol: 0,
      optVol: 0,
      currentCo2: currentCo2,
      optCo2: optCo2
    }
  ];

  const volData = {
    name: "Volume",
    current: currentVol,
    opt: optVol,
    // error: [optVol, optVol - currentVol],
    error: [0, currentVol - optVol]
  };
  const co2Data = {
    name: "CO2 Reduction",
    current: Math.round(currentCo2),
    opt: Math.round(optCo2),
    woodsafe: Math.round(woodsafeCo2),
  };

  const maxVol = currentVol > optVol ? currentVol : optVol;
  const maxCo2 = currentCo2 > optCo2 ? currentCo2 : optCo2;

  const ymaxVol = Math.round((maxVol * 1.1) / 10) * 10;
  const ymaxCo2 = Math.round((maxCo2 * 1.1) / 10) * 10;

  // const colorsErr = {
  //   0: "#000",
  //   1: "#123",
  //   2: "#ddd"
  // };

  return (
    <>
      <div
        style={{ display: "flex", width: "100%", margin: 20, maxWidth: 1200, justifyContent: (volCheck) ? 'space-between' : 'center' }}
        // ref={forwardRef}
        id="opt-charts"
      >
        {volCheck && <div style={{ height: 300, width: "20%", marginRight: "2%"}}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={[volData]}
              margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
              width="50%"
            >
              <XAxis
                dataKey="name"
                // scale="point"
                style={{ fontWeight: "bold" }}
                tickMargin={5}
                // padding={{ left: 0, right: 0 }}
              />
              <YAxis
                domain={[0, ymaxVol]}
                style={{ fontWeight: "bold", fontSize: 14 }}
                // type="number"
                label={{
                  value: "Usage",
                  angle: -90,
                  fontWeight: "bold",
                  position: "insideLeft",
                  offset: 0,
                  fontSize: 14
                  // position: "insideRight"
                }}
              />
              <Legend
                verticalAlign="top"
                // align="right"
                // layout="vertical"
                // wrapperStyle={{ visibility: "hidden" }}
              />
              <Bar
                dataKey="current"
                fill="#dddddd"
                label={<CustomBarLabel unit="liter" fontSize={14}/>}
                name="Current"
                animationDuration={100}
              />
              <Bar
                dataKey="opt"
                fill="#48cae4"
                label={<CustomBarLabel unit="liter" fontSize={14}/>}
                // shape={
                //   <CustomBarWithReferenceLine
                //     current={currentVol}
                //     opt={optVol}
                //   />
                // }
                name="Optimized"
                animationDuration={100}
              >
                {volData.error[1] !== 0 && (
                  <ErrorBar
                    dataKey="error"
                    width={30}
                    stroke="red"
                    direction="y"
                  />
                )}
              </Bar>
              {/* <ReferenceLine
                x="Volume"
                y={currentVol}
                stroke="black"
                label={{ value: "Current", position: "right" }}
              /> */}
            </BarChart>
          </ResponsiveContainer>
        </div>}
        <div style={{ height: 300, width: volCheck ? "78%" : "70%" }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={[co2Data]}
              margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
              width="50%"
              barGap={30}
            >
              <XAxis
                dataKey="name"
                style={{ fontWeight: "bold" }}
                tickMargin={5}
              />
              <YAxis
                domain={[0, ymaxCo2]}
                style={{ fontWeight: "bold" }}
                // unit=" kg"
                orientation="right"
                type="number"
                label={{
                  value: "CO2 (kg) / year",
                  angle: -90,
                  fontWeight: "bold",
                  position: "right",
                  offset: 10
                  // fontSize: 18
                  // position: "insideRight"
                }}
              />
              <Legend
                verticalAlign="top"
                className="opt-chart-legend"
                id="opt-chart-legend2"
                // align=""
                // layout="vertical"
                // wrapperStyle={{ left: -50 }}
                // wrapperStyle={{ lineHeight: "40px" }}
              />
              <Bar dataKey="current" fill="#dddddd" name="Current">
                <LabelList content={<CustomBarLabel unit="kg" />} position="top" />
              </Bar>
              <Bar dataKey="woodsafe" fill="#8aebaf" name="Woodsafe">
                <LabelList content={<CustomBarLabel unit="kg" />} position="top" />
                <LabelList content={<CustomReductionLabel current={Math.round(currentCo2)} />} position="top" />
                <LabelList content={<ReductionLine current={Math.round(currentCo2)} />} position="top" />
              </Bar>
              {optCo2 !== 0 && woodsafeCo2 !== optCo2 &&
              <Bar dataKey="opt" fill="#82ca9d" name="Woodsafe + Optimized">
                <LabelList content={<CustomBarLabel unit="kg" />} position="top" />
                <LabelList content={<CustomReductionLabel current={Math.round(currentCo2)} />} position="top" />
                <LabelList content={<ReductionLine current={Math.round(currentCo2)} />} position="top" />
              </Bar>}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

const ErrorMessage = ({ targetSum, optSum }) => {
  if (targetSum !== optSum) {
    return (
      <div>
        <p style={{ color: "red" }}>
          Error: Volumes don't match. {optSum} / {targetSum}
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p></p>
      </div>
    );
  }
};

const OptimizingUsage = ({
  potential,
  optimized,
  setOptimized,
  totalCo2,
  initialized,
  volCheck = true,
}) => {
  const [targetSum, setTargetSum] = useState(0);
  const [optSum, setOptSum] = useState(0);

  useEffect(() => {
    setTargetSum(getTotalSize(potential));
  }, [potential]);

  // useEffect(() => {

  //   const [woodsafeCo2_opt, _plastic_opt] = getTotalCo2(optimalSizes);
  //   console.log(
  //     "MAIN USE EFFECT CO2:",
  //     woodsafeCo2,
  //     plasticCo2,
  //     woodsafeCo2_opt
  //   );
  // }, [sizes.potential, optimalSizes]);

  useEffect(() => {
    if (initialized) {
      setOptSum(getTotalSize(optimized));
    } else {
      setOptSum(getTotalSize(potential));
    }
  }, []);

  const updateSizes = (value, size, _isCurrentUsage) => {
    const newState = { ...optimized, [size]: value };
    setOptSum(getTotalSize(newState));
    setOptimized(newState);
  };

  const optimizedReduction = totalCo2 ? (totalCo2.plastic - totalCo2.woodsafe_optimized) : 0;

  const totalWeight = getTotalWeight(potential);
  const totalWeight_opt = getTotalWeight(optimized);

  // const totalPlasticUse_plastic = totalWeight;
  // const totalPlasticUse_woodsafe = 0.2 * totalWeight;
  // const totalPlasticUse_opt = 0.2 * totalWeight_opt;
  const optimizedPlasticReduction = totalWeight - 0.2 * totalWeight_opt;

  // const woodsafePlasticReduction = 0.8 * totalWeight;
  // optimizedPlasticReduction -= woodsafePlasticReduction;

  // console.log(
  //   "Total plastic use.   plastic:",
  //   totalPlasticUse_plastic,
  //   "  woodsafe:",
  //   totalPlasticUse_woodsafe,
  //   "  opt:",
  //   totalPlasticUse_opt
  // );

  return (
    <>
      <ErrorMessage optSum={optSum} targetSum={targetSum} />
      <Co2Calculator
        isCurrentUsage={false}
        potential={potential}
        sizes={optimized}
        updateSizes={updateSizes}
        usageType="optimized"
        totalCo2Reduction={optimizedReduction}
        totalPlasticReduction={optimizedPlasticReduction}
      />
      <OptimizingChart
        currentVol={targetSum}
        optVol={optSum}
        currentCo2={getTotalCo2(potential)[1]}
        woodsafeCo2={getTotalCo2(potential)[0]}
        optCo2={getTotalCo2(optimized)[0]}
        volCheck={volCheck}
      />
    </>
  );
};

const CustomBarWithReferenceLine = (props) => {
  const { x, y, width, height, fill, current, opt, ...rest } = props;

  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill={fill} {...rest} />
      <line
        x1={x}
        y1={y + (height - (current / opt) * height)}
        x2={x + width}
        y2={y + (height - (current / opt) * height)}
        stroke="#ddd"
        strokeWidth="2"
      />
    </g>
  );
};

const CustomBarLabel = ({ x, y, width, height, value, unit, fontSize=18 }) => {
  return (
    <text
      x={x + width / 2}
      y={y + height / 2}
      fill="#333"
      fontSize={fontSize}
      textAnchor="middle"
      dy={6}
    >
      {`${value} ${unit}`}
    </text>
  );
};

const CustomReductionLabel = ({ x, y, width, value, current }) => {
  if (current === undefined || value === undefined) return null;
  const reduction = ((current - value) / current * 100).toFixed(0);
  const printRed = `-${reduction}%`;
  return (
    <Text x={x + width / 2} y={y - 15} textAnchor="middle" verticalAnchor="bottom" fill="#000" fontWeight="bold" fontSize={"21px"}>
      {printRed}
    </Text>
  );
};

const ReductionLine = ({ x, y, width, value, current }) => {
  const startY = current
  const endY = value
  const scale = (value - current) / 300; // Adjust 300 based on your chart height
  return (
    <g>
      <Line 
        x1={x + width / 2} 
        y1={300 - startY / scale} 
        x2={x + width / 2} 
        y2={300 - endY / scale} 
        stroke="#000" 
        strokeDasharray="3 3"
      />
      <Text 
        x={x + width / 2 + 5} 
        y={(600 - startY / scale - endY / scale) / 2} 
        textAnchor="start" 
        fill="#000"
      >
        {(startY - endY).toFixed(2)} kg
      </Text>
    </g>
  );
};

export default OptimizingUsage;

// useEffect(() => {
//   const updData = dataKeys.map((size) => {
//     const val = isNaN(ratios[size]) ? 0 : ratios[size];
//     const colVal = 0.5 + val * 0.5;
//     return {
//       name: size,
//       value: val * 100,
//       fill: `rgba(0, 121, 107, ${colVal})`
//     };
//   });
//   setData(updData);
//   const colVal = 0.5 + totalRatio * 0.5;
//   setTotalData((prevState) => ({
//     ...prevState,
//     value: totalRatio * 100,
//     fill: `rgba(0, 121, 107, ${colVal})`
//   }));
// }, [currentVol, optVol]);

// useEffect(() => {
//   const colVal = 0.5 + totalRatio * 0.5;
//   setTotalData((prevState) => ({
//     ...prevState,
//     value: totalRatio * 100,
//     fill: `rgba(0, 121, 107, ${colVal})`
//   }));
// }, [totalRatio]);

// {/* <div
// style={{ display: "flex", width: "100%", margin: 20, maxWidth: 1200 }}
// // ref={forwardRef}
// >
// <div style={{ height: 300, width: "100%", backgroundColor: "#ccc" }}>
//   <ResponsiveContainer width="100%" height="100%">
//     <BarChart /*width={100} height={500}*/ data={data} margin={0}>
//       {/* <CartesianGrid strokeDasharray="3 3" /> */}
//       <XAxis dataKey="name" style={{ fontWeight: "bold" }} />
//       <YAxis
//         domain={[0, 100]}
//         unit="volume"
//         style={{ fontWeight: "bold" }}
//         yAxisId="yaxis-volume"
//       />
//       <YAxis
//         domain={[0, 100]}
//         unit="kg CO2"
//         style={{ fontWeight: "bold" }}
//         yAxisId="yaxis-co2"
//         orientation="right"
//       />
//       <Bar dataKey="currentVol" fill="#8884d8" yAxisId="yaxis-volume" />
//       <Bar dataKey="optVol" fill="#82ca9d" yAxisId="yaxis-volume" />
//       <Bar dataKey="currentCo2" fill="#ffc658" yAxisId="yaxis-co2" />
//       <Bar dataKey="optCo2" fill="#ff7300" yAxisId="yaxis-co2" />

//       {/* <Bar dataKey="current" data={volData} yAxisId="yaxis-volume" />
//     <Bar dataKey="opt" data={volData} yAxisId="yaxis-volume" />
//     <Bar dataKey="current" data={co2Data} yAxisId="yaxis-co2" />
//     <Bar dataKey="opt" data={co2Data} yAxisId="yaxis-co2" /> */}
//     </BarChart>
//   </ResponsiveContainer>
// </div>
// </div> */}
