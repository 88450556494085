import { useState, useRef, useEffect } from "react";

import { Button } from "./Elements";
import { makeCSRD } from "./csrdReport";

import { Chart } from "./Chart";
import { Co2Calculator } from "./Co2Calculator";

import { getTotalCo2Reduction } from "./Co2Calculator";

const Reports = ({
  sizes,
  companyName,
  companyPeriod,
  printReportPotential,
  printReportOptimized,
  optimizingUsage,
  actualUsage,
  totalCo2
}) => {
  const [pdfGenerated, setPdfGenerated] = useState(false);
  const chartsRef = useRef(null);
  const tableRef = useRef(null);

  const printOutRef = useRef(null);

  useEffect(() => {
    const ok = true;
    if (ok) {
      let redPercent =
        getTotalCo2Reduction(sizes.actualUsageWS) / totalCo2.plastic;

      redPercent = Math.max(redPercent, 0.66);

      const redPercentStr = (redPercent * 100).toFixed();

      setTimeout(() => {
        makeCSRD(
          companyName,
          companyPeriod,
          redPercentStr,
          tableRef.current,
          chartsRef.current,
          printOutRef.current
        ).then(() => {
          // setPdfBlob(res);
          setPdfGenerated(false);
        });
      }, 1000);
    }
  }, [pdfGenerated]);

  return (
    <>
      <div className="downloads-wrapper">
        <Button
          text="CO2 Potential"
          // onClick={() => setPdfGenerated(true)}
          onClick={printReportPotential}
          // disabled={pdfGenerated}
        />
        <Button
          text="CO2 Potential and size optimization"
          // onClick={() => setPdfGenerated(true)}
          onClick={printReportOptimized}
          disabled={!optimizingUsage}
        />
        <Button
          text="Full Climate report for CSRD reporting"
          onClick={() => setPdfGenerated(true)}
          disabled={!actualUsage || pdfGenerated}
        />
      </div>
      {/* {pdfBlob && <PdfPreview pdfBlob={pdfBlob} />} */}
      {pdfGenerated && (
        <div
          className="main"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: 1440,
            height: 810,
            backgroundColor: "#fff",
            zIndex: -100
          }}
          ref={printOutRef}
        >
          {/* <Co2Calculator
            isCurrentUsage={false}
            // sizes={sizesPotential}
            sizes={sizes.potential}
            updateSizes={updateSizes}
            forwardRef={potentialTableRef}
          />*/}
          <Co2Calculator
            isPotentialUsage={false}
            // sizes={sizesCurrent}
            potential={sizes.actualUsageWS}
            sizes={sizes.actualUsageWS}
            updateSizes={() => null}
            ratios={sizes.ratios}
            forwardRef={tableRef}
            forPrint={true}
          />
          {/* <Chart
            ratios={sizes.ratios}
            totalRatio={sizes.totalRatio}
            forwardRef={chartsRef}
          /> */}
        </div>
      )}
    </>
  );
};

export default Reports;
