export const Button = ({ text, onClick, disabled, active, activeText }) => {
  const classesArr = ["button"];
  if (active) classesArr.push("active");
  if (disabled) classesArr.push("disabled");
  const classes = classesArr.join(" ");

  const inner = () => {
    if (active && activeText) {
      return (
        <>
          <span style={{ visibility: "hidden" }}>{text}</span>
          <span className="button-active-text">{activeText}</span>
        </>
      );
    } else {
      return <span>{text}</span>;
    }
  };

  return (
    <div className="button-wrapper">
      <a
        disabled={disabled}
        className={classes}
        onClick={(e) => {
          if (disabled) e.preventDefault();
          else onClick();
        }}
      >
        {inner()}
      </a>
    </div>
  );
};
