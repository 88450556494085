import React, { useState, useCallback, useEffect, useRef } from "react";

import Produkter from "./grupp_green_snabb.jpg";

import { Button } from "./Elements";

import {
  Co2Calculator,
  getTotalCo2Reduction,
  getTotalCo2
} from "./Co2Calculator";

import ActualUsageWS from "./ActualUsageWS";
import OptimizingUsage, { OptimizingChart } from "./OptimizingUsage";
import Reports from "./Reports";

import html2canvas from "html2canvas";

import ReactToImage from "./ReactToImage";
import { useReactToPrint } from "react-to-print";

// const sizeKeys = [0.5, 2, 3, 6, 12, 25, 50];

const thisYear = new Date().getFullYear();

const getStoredPotential = () => {
  try {
    const stored = localStorage.getItem("potential");
    if (stored) {
      const potentialSizes = JSON.parse(stored);
      return potentialSizes;
    } else {
      return { 0.5: "", 2: "", 3: "", 6: "", 12: "", 25: "", 50: "" };
    }
  } catch (error) {
    console.error("Error parsing stored data", error);
    return { 0.5: "", 2: "", 3: "", 6: "", 12: "", 25: "", 50: "" };
  }
};

const Main = ({ websiteLoc }) => {
  const [sizes, setSizes] = useState(() => ({
    // current: { 0.5: "", 2: "", 3: "", 6: "", 12: "", 25: "", 50: "" },
    potential: getStoredPotential(),
    actualUsageWS: getStoredPotential(), //{ 0.5: "", 2: "", 3: "", 6: "", 12: "", 25: "", 50: "" },
    ratios: { 0.5: NaN, 2: NaN, 3: NaN, 6: NaN, 12: NaN, 25: NaN, 50: NaN },
    totalRatio: NaN
  }));

  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    if (!initialLoad) {
      localStorage.setItem("potential", JSON.stringify(sizes.potential));
    } else {
      setInitialLoad(false);
    }
  }, [sizes.potential, initialLoad]);

  const [optimalSizes, setOptimalSizes] = useState({
    0.5: 0,
    2: 0,
    3: 0,
    6: 0,
    12: 0,
    25: 0,
    50: 0
  });

  const [addingActualUsage, toggleActualUsage] = useState(false);
  const [optimizingUsage, toggleOptimizingUsage] = useState(false);

  const [optimizingInitialized, setOptimizingInitialized] = useState(false);
  useEffect(() => {
    // only set optimized sizes to potential sizes the first time optimizing table is opened
    if (optimizingUsage && !optimizingInitialized) {
      setOptimalSizes(sizes.potential);
      setOptimizingInitialized(true);
    }
  }, [optimizingUsage]);
  const [actualUsageInitialized, setActualUsageInitialized] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [companyPeriod, setCompanyPeriod] = useState(thisYear);
  // const [pdfBlob, setPdfBlob] = useState(null);
  // const [pdfGenerated, setPdfGenerated] = useState(false);
  // const chartsRef = useRef(null);

  const [totalCo2, setTotalCo2] = useState({
    woodsafe: 0,
    woodsafe_optimized: 0,
    plastic: 0
  });

  // Update total Co2
  useEffect(() => {
    const [woodsafeCo2, plasticCo2] = getTotalCo2(sizes.potential);
    const [woodsafeCo2_opt, _plastic_opt] = getTotalCo2(optimalSizes);
    setTotalCo2({
      woodsafe: woodsafeCo2,
      woodsafe_optimized: woodsafeCo2_opt,
      plastic: plasticCo2
    });
  }, [sizes.potential, optimalSizes]);

  const parseValue = (value) => (value === "" ? 0 : parseInt(value));

  const calculateRatio = (actualUsage, potential) => {
    if (actualUsage === "" && potential === "") return NaN;
    const c = parseValue(actualUsage);
    const p = parseValue(potential);
    return c === 0 ? 0 : c >= p ? 1 : c / p;
  };

  useEffect(() => {
    // Setting 0 values instead of NaN values, only aesthetic
    const initialRatios = { ...sizes.ratios };
    let totalRatio = NaN;
    Object.entries(sizes.potential).forEach(([key, value]) => {
      if (value !== "") {
        initialRatios[key] = 1.0;
        totalRatio = 1.0;
      }
    });
    setSizes({ ...sizes, ratios: initialRatios, totalRatio: totalRatio });
  }, []);

  const updateSizes = useCallback((value, size, isPotentialUsage) => {
    const nbr = parseValue(value);
    if (isNaN(nbr)) return;

    setSizes((prevSizes) => {
      const newPotential = isPotentialUsage
        ? { ...prevSizes.potential, [size]: value }
        : prevSizes.potential;

      const newActualUsageWS = !isPotentialUsage
        ? { ...prevSizes.actualUsageWS, [size]: value }
        : prevSizes.actualUsageWS;

      const updatedRatios = {
        ...prevSizes.ratios,
        [size]: calculateRatio(newActualUsageWS[size], newPotential[size])
      };
      const newTotalRatio = calculateTotalRatio(newActualUsageWS, newPotential);

      return {
        potential: newPotential,
        actualUsageWS: newActualUsageWS,
        ratios: updatedRatios,
        totalRatio: newTotalRatio
      };
    });
  }, []);

  const calculateTotalRatio = (actualSizes, potentialSizes) => {
    const sumPotential = getTotalCo2Reduction(potentialSizes);
    const sumActual = getTotalCo2Reduction(actualSizes);
    return sumActual === 0
      ? 0
      : sumActual >= sumPotential
      ? 1
      : sumActual / sumPotential;
  };

  const handleExpanding = (sectionId, stateFunc, newState) => {
    // Expand the section
    stateFunc(newState);

    // Scrolls the table into view
    if (!newState) return;
    // Use a timeout to wait for the state to update and the DOM to re-render
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  // clicked from an a tag
  // onClick={(e) => handleExpanding(e,  toggleOptimizingUsage, !optimizingUsage)}

  const printReportPotentialRef = useRef(null);
  const printReportOptimizedRef = useRef(null);

  const [printState, setPrintState] = useState(3);

  const downloadAsImage = async (ref, fileName) => {
    if (ref.current) {
      try {
        const canvas = await html2canvas(ref.current);
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = fileName;
        link.click();
        return true;
      } catch (error) {
        console.error('Error generating image:', error);
        return false;
      }
    }
    return false;
  };
  
  const handleDownloadPotential = async () => {
    setPrintState(0);
    await new Promise((resolve) => setTimeout(resolve, 500));
    const success = await downloadAsImage(printReportPotentialRef, 'potential_report.png');
    setPrintState(success ? 3 : 2);
  };
  
  const handleDownloadOptimized = async () => {
    setPrintState(1);
    await new Promise((resolve) => setTimeout(resolve, 500));
    const success = await downloadAsImage(printReportOptimizedRef, 'optimized_report.png');
    setPrintState(success ? 3 : 2);
  };

  return (
    <>
      <div
        className={`popup_print ${printState === 0 ? "print_show" : ""}`}
        ref={printReportPotentialRef}
      >
        {printState === 0 && (
          <>
            <h2 style={{ fontSize: "2em" }}>
              Potential CO2 savings - Woodsafe
            </h2>
            <Co2Calculator
              isPotentialUsage={true}
              sizes={sizes.potential}
              updateSizes={updateSizes}
            />
            <OptimizingChart
              currentVol={0}
              optVol={0}
              currentCo2={getTotalCo2(sizes.potential)[1]}
              woodsafeCo2={getTotalCo2(sizes.potential)[0]}
              optCo2={0}
              volCheck={false}
            />
          </>
        )}
      </div>
      <div
        className={`popup_print ${printState === 1 ? "print_show" : ""}`}
        ref={printReportOptimizedRef}
      >
        {printState === 1 && (
          <>
            <h2 style={{ fontSize: "2em" }}>
              Potential CO2 savings - Woodsafe + Size Optimization
            </h2>
            {optimizingUsage && (
              <OptimizingUsage
                potential={sizes.potential}
                optimized={optimalSizes}
                setOptimized={setOptimalSizes}
                totalCo2={totalCo2}
                initialized={optimizingInitialized}
                volCheck={false}
              />
            )}
          </>
        )}
      </div>
      <div className={`popup_print ${printState === 2 ? "print_show" : ""}`}>
        {printState === 2 && (
          <>
            <h1>Actual CO2 savings - Woodsafe</h1>
            <ActualUsageWS
              sizes={sizes}
              updateSizes={updateSizes}
              companyName={companyName}
              companyPeriod={companyPeriod}
            />
          </>
        )}
      </div>
      <div className="main">
        {/* <h3>{websiteLoc === "start" ? "Start Page" : "Calculator Page"}</h3> */}
        <img
          style={{ width: "50%", height: "auto" }}
          src={Produkter}
          alt="Products"
        ></img>{" "}
        <div className="text-section">
          <h1>Potential CO2 savings &ndash; usage today</h1>
          <p>
            Fill in similar size use per size per year, based on the estimated usage historically of fossil based bins. The calculator will show
            you how much CO2 you potentially can save per year by switching to
            Woodsafe from fossil based bins.
          </p>
        </div>
        <Co2Calculator
          isPotentialUsage={true}
          sizes={sizes.potential}
          updateSizes={updateSizes}
        />
        <div className="text-section" id="optimizingSection">
          {/* <hr /> */}
          <h1>Optimize size of bins</h1>
          <p>
            If you can use larger sizes of bins you will save use of material,
            money and CO2 emissions. Goal is to minimize the kg of the bin vs kg waste,
            e.g. shift from 3L to 12L bins will reduce CO2 with 75% and reduce
            the use of plastic with 27% &ndash; and reduce cost!
          </p>
          <p>
          Fill number of larger bins you potentially can use.
          The left “volume-graph” will help guide you to calibrate the similar volume of waste based on what you filled in in Step 1 – Usage today.
          </p>
        </div>
        <div className="expanding-section">
          {optimizingUsage && (
            <OptimizingUsage
              potential={sizes.potential}
              optimized={optimalSizes}
              setOptimized={setOptimalSizes}
              totalCo2={totalCo2}
              initialized={optimizingInitialized}
            />
          )}
        </div>
        <div className="text-section">
          <div className="toggle-expanding">
            <div className="divider" />
            <Button
              text="Optimize current usage"
              // onClick={() => toggleOptimizingUsage(!optimizingUsage)}
              onClick={() =>
                handleExpanding(
                  "optimizingSection",
                  toggleOptimizingUsage,
                  !optimizingUsage
                )
              }
              active={optimizingUsage}
              activeText="Close"
            />
            <div className="divider" />
          </div>
        </div>
        <div className="text-section" id="actualUsageSection">
          {/* <hr /> */}
          <h1>Fill in actual use per period to create climate report</h1>
          <p>
          To create a climate report based on actual use of Woodsafe for a time period (month or year) fill in the time period, actual number of WoodSafe bins used, and name your report.
          This report will show you the total savings for this period and can be used to fulfill the CSRD requirement and to support your internal reporting. The report is produced by 3:rd party external LCA and CSRD experts.
          </p>
        </div>
        <div className="expanding-section">
          {addingActualUsage && (
            <>
              <div className="name-period">
                <label>Company Name</label>
                <input
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="company-input"
                />
                <label>Period</label>
                <input
                  type="number"
                  value={companyPeriod}
                  onChange={(e) => setCompanyPeriod(e.target.value)}
                  className="company-input"
                />
              </div>
              <ActualUsageWS
                sizes={sizes}
                updateSizes={updateSizes}
                companyName={companyName}
                companyPeriod={companyPeriod}
              />
            </>
          )}
        </div>
        <div className="text-section">
          <div className="toggle-expanding">
            <div className="divider" />
            <Button
              text="Add actual usage"
              // onClick={() => toggleActualUsage(!addingActualUsage)}
              onClick={() => {
                if (optimizingInitialized && !actualUsageInitialized) {
                  setActualUsageInitialized(true);
                  setSizes((prevSizes) => ({
                    ...prevSizes,
                    actualUsageWS: optimalSizes
                  }));
                }
                handleExpanding(
                  "actualUsageSection",
                  toggleActualUsage,
                  !addingActualUsage
                )
              }
              }
              active={addingActualUsage}
              activeText="Close"
            />
            <div className="divider" />
          </div>
        </div>
        <div className="text-section">
          <h1>Climate Reports</h1>
          <Reports
            sizes={sizes}
            companyName={companyName}
            companyPeriod={companyPeriod}
            printReportPotential={handleDownloadPotential}
            printReportOptimized={handleDownloadOptimized}
            optimizingUsage={optimizingUsage}
            actualUsage={addingActualUsage}
            totalCo2={totalCo2}
          />
        </div>
        <div style={{ fontSize: "10px", marginTop: 50 }}>
          The estimations in the “climate calculator” is based on:
          <ul>
            <li>
              Average weight of the most common uses waste containers per size.
            </li>
            <li>
              The estimated CO2 generated from 1kg fossil-based container is
              5,7kg.
            </li>
            <li>
              The reduction in CO2 is -66% with WoodSafe vs fossil-based
              polypropylene according to documented LCA.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Main;
