import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer
} from "recharts";
import { useEffect, useState } from "react";
import React, { PureComponent } from "react";

const xAxisTicks = {
  0.5: "0,5 liter",
  2: "2 liter",
  3: "3 liter",
  6: "6 liter",
  12: "12 liter",
  25: "25 liter",
  50: "50 liter"
};
const xAxisTicksShort = {
  0.5: "0,5 L",
  2: "2 L",
  3: "3 L",
  6: "6 L",
  12: "12 L",
  25: "25 L",
  50: "50 L"
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          // transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

// const CustomYAxisTick = (props) => {
//   const {x, y, stroke, payload} = props
//   console.log("Y Ticks:", props)
//   return
// }

const dataKeys = [0.5, 2, 3, 6, 12, 25, 50];

export const Chart = ({ ratios, totalRatio, forwardRef }) => {
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState({
    name: "Total",
    value: 0,
    fill: "rgba(0, 121, 107, 0)"
  });

  useEffect(() => {
    const updData = dataKeys.map((size) => {
      const val = isNaN(ratios[size]) ? 0 : ratios[size];
      const colVal = 0.5 + val * 0.5;
      return {
        name: size,
        value: val * 100,
        fill: `rgba(0, 121, 107, ${colVal})`
      };
    });
    setData(updData);
    const colVal = 0.5 + totalRatio * 0.5;
    setTotalData((prevState) => ({
      ...prevState,
      value: totalRatio * 100,
      fill: `rgba(0, 121, 107, ${colVal})`
    }));
  }, [ratios, totalRatio]);

  // useEffect(() => {
  //   const colVal = 0.5 + totalRatio * 0.5;
  //   setTotalData((prevState) => ({
  //     ...prevState,
  //     value: totalRatio * 100,
  //     fill: `rgba(0, 121, 107, ${colVal})`
  //   }));
  // }, [totalRatio]);
  return (
    <div
      style={{ display: "flex", width: "100%", margin: 20, maxWidth: 1200 }}
      ref={forwardRef}
    >
      <div style={{ height: 300, width: "25%" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart /*width={100} height={500}*/ data={[totalData]} margin={0}>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis
              dataKey="name"
              style={{ fontWeight: "bold" }}
              tickMargin={5}
            />
            <YAxis domain={[0, 100]} unit="%" style={{ fontWeight: "bold" }} />
            <Bar dataKey="value" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div style={{ height: 300, width: "75%" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={0}
            id="chart_right"
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            {/* <Tooltip /> */}
            <XAxis
              dataKey="name"
              tickFormatter={(e) => xAxisTicks[e]}
              tickMargin={5}
              style={{ fontWeight: "bold" }}
            />
            <YAxis
              domain={[0, 100]}
              unit="%"
              /*hide*/
              style={{ fontWeight: "bold" }}
            />
            <Bar dataKey="value" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
