import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";

import "./App.css";

import Main from "./Main";
import MainSimple from "./MainSimple";

function App() {
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const loc = params.get("loc");
    if (loc) {
      setLocation(loc);
    }
    setLoading(false);
  }, []);

  if (loading || location === "start") {
    return (
      <div className="App">
        <MainSimple websiteLoc={location} />
      </div>
    );
  }
  return (
    <div className="App">
      <Main websiteLoc={location} />
    </div>
  );
}
export default App;
