import { useState } from "react";

const numberFormatter = new Intl.NumberFormat();

export const getTotalCo2Reduction = (sizes) => {
  if (!sizes) return 0;
  return (
    sizes[0.5] * 0.342 +
    sizes[2] * 0.828 +
    sizes[3] * 0.997 +
    sizes[6] * 1.862 +
    sizes[12] * 2.953 +
    sizes[25] * 4.985 +
    sizes[50] * 7.731
  );
};

export const getTotalWeight = (sizes) => {
  if (!sizes) return 0;
  const sizeWeights = {
    0.5: 0.088,
    2: 0.218,
    3: 0.267,
    6: 0.495,
    12: 0.785,
    25: 1.325,
    50: 2.055
  };

  return Object.entries(sizes).reduce(
    (total, [key, val]) => total + sizeWeights[key] * val,
    0
  );
};

export const getTotalCo2 = (sizes) => {
  if (!sizes) return [0, 0];
  // const woodsafeCo2PerKg = 1.94;
  // const plasticCo2PerKg = 5.71;
  const totalWeight = getTotalWeight(sizes);
  const woodsafeCo2 = 1.94 * totalWeight;
  const plasticCo2 = 5.71 * totalWeight;

  return [woodsafeCo2, plasticCo2];
};

export const getFossilPlasticReduction = (sizes) => {
  const totalWeight = getTotalWeight(sizes);
  return 0.8 * totalWeight;
};

export const Co2Calculator = ({
  isPotentialUsage,
  sizes,
  updateSizes,
  ratios,
  forwardRef,
  usageType,
  totalCo2Reduction,
  totalPlasticReduction,
  potential=null,
  forPrint=false,
}) => {
  const handleKeyDown = (e) => {
    if (["-", ".", ","].includes(e.key)) {
      e.preventDefault();
    }
  };
  const handleInput = (event, size) => {
    const value = event.target.value;

    // Remove invalid characters if somehow entered
    if (value.includes(".") || value.includes(",") || value.includes("-")) {
      event.target.value = value.replace(/[.,-]/g, "");
    }

    // Enforce maximum length
    if (value.length > 6) {
      event.target.value = value.slice(0, 6);
    }
  };
  const handleInputChange = (event, size) => {
    const value = event.target.value;
    const re = /^[0-9]*$/;
    if (!re.test(value)) {
      // should already be checked
      console.error("abort for ", value);
      event.preventDefault();
      return;
    }
    if (value === "" || re.test(value)) {
      updateSizes(value, size, isPotentialUsage);
    }
  };

  const formatNumber = (number) => {
    return numberFormatter.format(number);
  };
  const formatRatio = (number) => {
    if (isNaN(number)) return "—";
    return numberFormatter.format((number * 100).toFixed()) + "%";
  };

  let tableTextCap, tableText, tableId;
  if (usageType) {
    tableTextCap = usageType.charAt(0).toUpperCase() + usageType.slice(1);
    tableText = usageType;
    tableId = tableText + "-table";
  } else {
    tableTextCap = isPotentialUsage ? "Potential" : "Actual";
    tableText = isPotentialUsage ? "potential" : "actual";
    tableId = tableText + "-table";
  }

  const sum = totalCo2Reduction || getTotalCo2Reduction(sizes);
  const fossilPlasticReduction =
    totalPlasticReduction || getFossilPlasticReduction(sizes);

  // PERCENTAGE REDUCTION
  const sizeChecker = potential ? potential : sizes;
  const reductionCo2Percentage = (100-((getTotalCo2(sizeChecker)[1] - sum) / getTotalCo2(sizeChecker)[1] * 100)).toFixed(0);
  const reductionPlasticPercentage = (100-((getTotalWeight(sizeChecker) - fossilPlasticReduction) / getTotalWeight(sizeChecker) * 100)).toFixed(0);

  return (
    <div className="co2-calculator">
      <table className="table" id={tableId} ref={forwardRef}>
        <thead>
          <tr>
            <th>{tableTextCap} usage</th>
            <th>0,5 liter</th>
            <th>2 liter</th>
            <th>3 liter</th>
            <th>6 liter</th>
            <th>12 liter</th>
            <th>25 liter</th>
            <th>50 liter</th>
          </tr>
        </thead>
        <tbody>
          <tr style={forPrint ? {width:'100%'} : {}}>
            <td># USE/YEAR/SIZE</td>
            <td>
              {forPrint ?
                <span className="td-print">{sizes[0.5]}</span>
              : <input
                type="number"
                min="0"
                step={1}
                onKeyDown={(e) => handleKeyDown(e)}
                onInput={(e) => handleInput(e)}
                value={sizes[0.5]}
                onChange={(event) => handleInputChange(event, 0.5)}
              />}
            </td>
            <td>
              {forPrint ?
                <span className="td-print">{sizes[2]}</span>
              : <input
                type="number"
                min="0"
                step={1}
                onKeyDown={(e) => handleKeyDown(e)}
                onInput={(e) => handleInput(e)}
                value={sizes[2]}
                onChange={(event) => handleInputChange(event, 2)}
              />}
            </td>
            <td>
              {forPrint ?
                <span className="td-print">{sizes[3]}</span>
              : <input
                type="number"
                min="0"
                step={1}
                onKeyDown={(e) => handleKeyDown(e)}
                onInput={(e) => handleInput(e)}
                value={sizes[3]}
                onChange={(event) => handleInputChange(event, 3)}
              />}
            </td>
            <td>
              {forPrint ?
                <span className="td-print">{sizes[6]}</span>
              : <input
                type="number"
                min="0"
                step={1}
                onKeyDown={(e) => handleKeyDown(e)}
                onInput={(e) => handleInput(e)}
                value={sizes[6]}
                onChange={(event) => handleInputChange(event, 6)}
              />}
            </td>
            <td>
              {forPrint ?
                <span className="td-print">{sizes[12]}</span>
              : <input
                type="number"
                min="0"
                step={1}
                onKeyDown={(e) => handleKeyDown(e)}
                onInput={(e) => handleInput(e)}
                value={sizes[12]}
                onChange={(event) => handleInputChange(event, 12)}
              />}
            </td>
            <td>
              {forPrint ?
                <span className="td-print">{sizes[25]}</span>
              : <input
                type="number"
                min="0"
                step={1}
                onKeyDown={(e) => handleKeyDown(e)}
                onInput={(e) => handleInput(e)}
                value={sizes[25]}
                onChange={(event) => handleInputChange(event, 25)}
              />}
            </td>
            <td>
              {forPrint ?
                <span className="td-print">{sizes[50]}</span>
              : <input
                type="number"
                min="0"
                step={1}
                onKeyDown={(e) => handleKeyDown(e)}
                onInput={(e) => handleInput(e)}
                value={sizes[50]}
                onChange={(event) => handleInputChange(event, 50)}
              />}
            </td>
          </tr>
          {/* <tr>
            <th>WoodSafe®</th>
            <th><img src="path/to/image1.jpg" alt="1" /></th>
            <th><img src="path/to/image2.jpg" alt="2" /></th>
            <th><img src="path/to/image3.jpg" alt="3" /></th>
            <th><img src="path/to/image4.jpg" alt="4" /></th>
            <th><img src="path/to/image5.jpg" alt="5" /></th>
            <th><img src="path/to/image6.jpg" alt="6" /></th>
            <th><img src="path/to/image7.jpg" alt="7" /></th>
          </tr> */}
          <tr>
            <td>CO2 reduction</td>
            <td>{formatNumber((sizes[0.5] * 0.342).toFixed())} kg</td>
            <td>{formatNumber((sizes[2] * 0.828).toFixed())} kg</td>
            <td>{formatNumber((sizes[3] * 0.997).toFixed())} kg</td>
            <td>{formatNumber((sizes[6] * 1.862).toFixed())} kg</td>
            <td>{formatNumber((sizes[12] * 2.953).toFixed())} kg</td>
            <td>{formatNumber((sizes[25] * 4.985).toFixed())} kg</td>
            <td>{formatNumber((sizes[50] * 7.731).toFixed())} kg</td>
          </tr>
          <tr className="total-row">
            <td>Total {tableText} CO2 reduction</td>
            <td colSpan={7}>{formatNumber(sum.toFixed())} kg per year {(reductionCo2Percentage > 0.0 && reductionCo2Percentage <= 100.0) && <span style={{fontSize: "larger", marginLeft: '2px', fontStyle: 'italic'}}>-{reductionCo2Percentage}%</span>}</td>
          </tr>
          <tr className="total-row">
            <td>Reduction of fossil plastic use</td>
            <td colSpan={7}>
              {formatNumber(fossilPlasticReduction.toFixed())} kg per year {(reductionPlasticPercentage > 0.0 && reductionPlasticPercentage <= 100.0) && <span style={{fontSize: "larger", marginLeft: '2px', fontStyle: 'italic'}}>-{reductionPlasticPercentage}%</span>}
            </td>
          </tr>
          {/* <tr>
            <td colSpan={8} style={{ paddingTop: "25px" }}>
              This yearly reduction in CO2 emission is equivalent to:{" "}
            </td>
          </tr> */}
          {/* <tr>
            <td>CO2 savings equivalent to</td>
            <td>{formatNumber((sum/25).toFixed())}</td>
            <td style={{textAlign: 'left'}} colSpan={6}>Trees (One tree reduce aprox. 25kg CO2 per year)</td>
          </tr> */}
          <tr>
            <td rowSpan={2}>
              Yearly CO2 savings{" "}
              <span style={{ display: "inline-block" }}>equivalent to</span>
            </td>
            <td>{formatNumber((sum / 0.093).toFixed())}</td>
            <td style={{ textAlign: "left" }} colSpan={6}>
              km driving (average 93g/km CO2)
            </td>
          </tr>
          <tr>
            {/* <td style={{ display: "none" }}></td> */}
            {/* <td>CO2 savings equivalent to</td> */}
            <td style={{ fontWeight: "normal" }}>
              {formatNumber((sum / 0.093 / 40075).toFixed(1))}
            </td>
            <td style={{ textAlign: "left" }} colSpan={6}>
              times around the world in a car
            </td>
          </tr>
          {/* <tr>
            <td>CO2 savings equivalent to</td>
            <td>{formatNumber((sum/430).toFixed(2))}</td>
            <td style={{textAlign: 'left'}} colSpan={6}>barrels of oil</td>
          </tr>
          <tr>
            <td>CO2 savings equivalent to</td>
            <td>{formatNumber((sum/22).toFixed(2))}</td>
            <td style={{textAlign: 'left'}} colSpan={6}>propane cylinders</td>
          </tr>
          <tr>
            <td>CO2 savings equivalent to</td>
            <td>{formatNumber((sum/(181.3*1000)).toFixed(7))}</td>
            <td style={{textAlign: 'left'}} colSpan={6}>railcars with coal</td>
          </tr> */}
          {/* Add more rows as needed */}
        </tbody>
        {false && ratios && (
          <tfoot>
            <tr>
              <td>% of potential implemented</td>
              <td>{formatRatio(ratios[0.5])}</td>
              <td>{formatRatio(ratios[2])}</td>
              <td>{formatRatio(ratios[3])}</td>
              <td>{formatRatio(ratios[6])}</td>
              <td>{formatRatio(ratios[12])}</td>
              <td>{formatRatio(ratios[25])}</td>
              <td>{formatRatio(ratios[50])}</td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};
