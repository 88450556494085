import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import html2canvas from "html2canvas";

const getImageFromRef = async (printOutRef) => {
  const canvas = await html2canvas(printOutRef, {
    scale: 2, // Increase scale for better quality
    useCORS: true, // Allow images from other domains
    logging: false // Disable logging for better performance
  });

  return canvas.toDataURL("image/png");
};

const getSourceSansFont = async (type) => {
  const response = await fetch(`/source_sans/source-sans-pro-${type}.ttf`);
  if (!response.ok) {
    throw new Error(
      `Failed to fetch source-sans-pro-${type}: ${response.statusText}`
    );
  }
  const fontBuffer = await response.arrayBuffer();
  return fontBuffer;
};

export const makeCSRD = async (
  companyName,
  period,
  actualCo2Reduction,
  tableRef,
  chartsRef,
  printOutRef
) => {
  // MARTIN
  // const img = getImg(tableRef, chartsRef);

  const response = await fetch("/CSRD_Report_form.pdf");
  if (!response.ok) {
    throw new Error(`Failed to fetch PDF: ${response.statusText}`);
  }

  const arrayBuffer = await response.arrayBuffer();
  const pdfDoc = await PDFDocument.load(arrayBuffer);

  // Add fonts
  pdfDoc.registerFontkit(fontkit);
  const regularFontBuf = await getSourceSansFont("regular");
  const regularFont = await pdfDoc.embedFont(regularFontBuf);
  const boldFontBuf = await getSourceSansFont("semibold");
  const boldFont = await pdfDoc.embedFont(boldFontBuf);

  const form = pdfDoc.getForm();

  const formCompanyField = form.getTextField("company_name");
  const formPeriodField = form.getTextField("period");
  const formRedInYearField = form.getTextField("reduction_in_year");

  formCompanyField.setFontSize(25);
  formPeriodField.setFontSize(25);

  formCompanyField.setText(companyName);
  formPeriodField.setText(period.toFixed());
  formRedInYearField.setText(`${actualCo2Reduction}% in ${period},`);
  formCompanyField.updateAppearances(regularFont);
  formPeriodField.updateAppearances(regularFont);
  formRedInYearField.updateAppearances(boldFont);

  // perhaps we need to update appearance with fonts etc
  form.flatten();

  const pages = pdfDoc.getPages();
  const imgPage = pages[4];
  const { width, height } = imgPage.getSize();

  const image = await getImageFromRef(printOutRef);

  imgPage.drawImage(await pdfDoc.embedPng(image), {
    x: 0,
    y: 0,
    width: width,
    height: height
  });

  // Download the file
  const pdfBytes = await pdfDoc.save();
  triggerDownload(pdfBytes);
};

const triggerDownload = (pdfBytes) => {
  // downloads the pdf
  const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
  const downloadUrl = window.URL.createObjectURL(pdfBlob);
  const downloadLink = document.createElement("a");
  downloadLink.href = downloadUrl;
  downloadLink.download = "CSRD Report.pdf"; // Specifying the filename
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(downloadUrl);
};
